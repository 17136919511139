import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { extractLanguage } from './lang/lang';
import { configureFetchDisplayMessages } from './lang/services';
import { configureMockUserManager, configureUserManager, createUserManager } from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored, idTokenClaimsStored } from './tokenHandling/tokenHandlingSlice';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { SessionRenewedResult } from './types';
import { getLocale } from './lang/langSlice';
import {
    accountType as accountTypeO,
    AccountType,
    accountTypeObtained,
    userProfileObtained,
    userSessionExpired,
    userSessionRenewed,
} from './login/loginSlice';
import { getAccountType } from './accountTypeUtils';
import { User } from 'oidc-client-ts';

export interface OAuthConfig {
    onSessionExpired: () => void;
    onSessionRenewed: (result: SessionRenewedResult) => void;
}

export const main = async (renderApp: (user: User) => void) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(idTokenClaimsStored(result.idTokenClaims));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(result.locale);
        },
    } as OAuthConfig;

    const isAllowedToMockAuth = import.meta.env.DEV;

    const userManager =
        isAllowedToMockAuth && config.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    // @ts-ignore
    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, userManager.signinSilent.bind(userManager));
    // @ts-ignore
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, userManager.signinSilent.bind(userManager));

    try {
        userManager.clearStaleState().catch((error) => reportErrorToSentry(error));
    } catch (error) {
        reportErrorToSentry(error);
    }

    try {
        const user = await attemptInitialSignIn(userManager);

        if (user && typeof user.profile.account === 'string') {
            let accountType: AccountType;
            if (user.profile.logistics_tenant) {
                accountType = accountTypeO.LOGISTICS_ACCOUNT;
            } else {
                accountType = await getAccountType(user.profile.account);
            }
            store.dispatch(accountTypeObtained(accountType));
            renderApp(user);
        }
    } catch (error: unknown) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
