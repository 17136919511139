import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accessToken } from './accessToken';
import { UserProfile } from 'oidc-client-ts';
import { AccessToken } from '../types';
import { RootState } from '../setup/store';

interface TokenHandlingState {
    accessToken: AccessToken;
    idToken: string | null;
    idTokenClaims: UserProfile | null;
}

const initialState: TokenHandlingState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
    idTokenClaims: null,
};

const tokenHandlingSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<string | null>) => {
            state.idToken = action.payload;
        },
        idTokenClaimsStored: (state, action: PayloadAction<UserProfile>) => {
            state.idTokenClaims = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored, idTokenClaimsStored } = tokenHandlingSlice.actions;

export default tokenHandlingSlice.reducer;

export const getAccessToken = (state: RootState) => state.tokenHandling.accessToken;

export const getIdToken = (state: RootState) => state.tokenHandling.idToken;

export const getIdTokenClaims = (state: RootState) => state.tokenHandling.idTokenClaims;

export const getAccountId = (state: RootState) =>
    typeof state.tokenHandling?.idTokenClaims?.account === 'string' ? state.tokenHandling.idTokenClaims.account : null;
